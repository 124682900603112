<template>
  <div class="filters">
    <div class="primary_filters">
      <custom-input
          :delay="800"
          label="Имя"
          :name="'name'"
          :inputWidth="100"
          cleanable
          v-model="searchParams.name"
      />
      <custom-input
          :delay="800"
          :name="'email'"
          label="Email"
          :inputWidth="100"
          cleanable
          v-model="searchParams.email"
      />
      <custom-select-2
          v-model="searchParams.role"
          name="role"
          label="Роль"
          :options="roleOptions"
      />
      <custom-select-2
          v-model="searchParams.referenceProfileId"
          name="reference"
          label="Эталонный профиль"
          :options="referenceProfileOptions"
      />
    </div>
    <div class="secondary_filters">
      <custom-checkbox
          v-if="group !== 'all'"
          v-model="searchParams.onlyGroup"
          :checked="!!searchParams.onlyGroup"
          label="Не состоит в других группах"
          :from-value="true"
          :value="1"
      />
      <div v-if="isTest">
        <custom-checkbox
            :disabled="!!searchParams.noActiveTest"
            v-model="searchParams.activeTest"
            :checked="searchParams.activeTest"
            label="Имеет активный тест"
            :from-value="true"
            :value="1"
        />
        <custom-checkbox
            :disabled="!!searchParams.activeTest"
            v-model="searchParams.noActiveTest"
            :checked="searchParams.noActiveTest"
            label="Не имеет активных тестов"
            :from-value="true"
            :value="1"
        />
      </div>
      <div v-else>
        <custom-checkbox
            :disabled="!!searchParams.noActiveCourse"
            v-model="searchParams.activeCourse"
            :checked="searchParams.activeCourse"
            label="Имеет активный курс"
            :from-value="true"
            :value="1"
        />
        <custom-checkbox
            :disabled="!!searchParams.activeCourse"
            v-model="searchParams.noActiveCourse"
            :checked="searchParams.noActiveCourse"
            label="Не имеет активных курсов"
            :from-value="true"
            :value="1"
        />
      </div>
    </div>
    <div class="control_button">
      <button @click="clearSearch" class="btn btn-w clear-btn">Очистить</button>
      <button @click="searchRequest" class="btn btn-accent">Фильтровать</button>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {computed, onBeforeMount, reactive, ref, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  name: "FilterBlock",
  components: {CustomCheckbox, CustomSelect2, CustomInput},
  props: {
    product: String,
    group: String,
    isTest: Boolean,
  },
  setup(props, {emit}) {
    const store = useStore();
    const referenceProfiles = computed(
        () => store.state.analytics.referenceProfiles
    )

    let referenceProfileOptions = computed(() => {
      return [{
          id: 0,
          text: 'Не выбрано'
        }].concat(referenceProfiles.value.map((profile) => {
          return {
            id: profile.id,
            text: profile.title,
          }
      }));
    });

    let searchParams = reactive({
          name: "",
          email: "",
          role: "Не выбрано",
          referenceProfileId: "0",
          onlyGroup: 0,
          activeTest: 0,
          noActiveTest: 0,
          activeCourse: 0,
          noActiveCourse: 0,
        }),
        profile = computed(() => store.state.profile.profile),
        roleOptions = computed(() =>
            !profile.value || profile.value.role !== "superadmin"
                ? ["Не выбрано", "Администратор", "Сотрудник"]
                : [
                  "Не выбрано",
                  "Администратор",
                  "Сотрудник",
                  "Частное лицо",
                ]
        );

    const searchRequest = () => {
      emit('loading');
      if (searchParams.activeCourse && searchParams.noActiveCourse) {
        searchParams.noActiveCourse = 0;
      }

      if (searchParams.activeTest && searchParams.noActiveTest) {
        searchParams.noActiveTest = 0;
      }

      store
          .dispatch("group/getProductGroup", {
            product: props.product,
            id: props.group,
            search_data: searchParams,
          }).then(() => emit('loaded'));
    };

    const clearSearch = () => {
      searchParams.role = "Не выбрано";
      searchParams.referenceProfileId = "0";
      searchParams.name = "";
      searchParams.email = "";
      searchParams.onlyGroup = 0;
      searchParams.activeTest = 0;
      searchParams.noActiveTest = 0;
      searchParams.activeCourse = 0;
      searchParams.noActiveCourse = 0;

      searchRequest();
    };

    onBeforeMount(() => {
      store.dispatch("analytics/getReferenceProfiles");
    });

    return {
      searchParams,
      roleOptions,
      clearSearch,
      searchRequest,
      referenceProfileOptions,
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  position: sticky;
  padding: 5px 0;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
}

.secondary_filters {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.control_button {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .secondary_filters {
    margin: 10px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>